<template>
  <div class="wrapper rounded-xl">
    <div class="wrapper__header">
      <div class="header__title">{{ $t('monitoring.commands') }}</div>
      <close-icon class="header__close" @click.native="closeCommands" />
    </div>
    <div class="command__search">
      <input
        :placeholder="$t('search')"
        class="command__input"
        v-model="searchCommands"
      />
      <svg
        class="command__search__icon pointer"
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M11.8857 5.94286C11.8857 4.76747 11.5372 3.61848 10.8842 2.64118C10.2312 1.66389 9.30301 0.902176 8.21709 0.452376C7.13118 0.00257492 5.93627 -0.115113 4.78346 0.114193C3.63066 0.343499 2.57175 0.909501 1.74062 1.74062C0.909501 2.57175 0.343499 3.63066 0.114193 4.78346C-0.115113 5.93627 0.00257492 7.13118 0.452376 8.21709C0.902176 9.30301 1.66389 10.2312 2.64118 10.8842C3.61848 11.5372 4.76747 11.8857 5.94286 11.8857C7.36065 11.8856 8.73194 11.3799 9.81029 10.4594L10.0571 10.7063V11.4286L14.6286 16L16 14.6286L11.4286 10.0571H10.7063L10.4594 9.81029C11.3799 8.73194 11.8856 7.36065 11.8857 5.94286ZM1.82781 5.94286C1.82781 3.65715 3.65638 1.82857 5.9421 1.82857C8.22781 1.82857 10.0564 3.65715 10.0564 5.94286C10.0564 8.22857 8.22781 10.0571 5.9421 10.0571C3.65638 10.0571 1.82781 8.22857 1.82781 5.94286Z"
          fill="#292929"
        />
      </svg>
    </div>
    <div @click="openFooter" class="command__send">
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M19 1L9.1 10.9M19 1L12.7 19L9.1 10.9M19 1L1 7.3L9.1 10.9"
          stroke="#446CA7"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>

      <p>{{ $t('monitoring.send_command') }}</p>
    </div>
    <div class="wrapper__body">
      <div class="table">
        <div class="thead">
          <div class="tr" v-for="(item, index) in thead" :key="index">
            {{ item.columnName }}
          </div>
        </div>
        <div class="tbody overflow-y-auto">
          <div
            :class="index % 2 ? ' ' : 'stripped'"
            class="td"
            v-for="(item, index) in filterCommands"
            :key="item.id"
          >
            <div class="td__info">{{ item.created }}</div>
            <div class="td__info">
              <div v-if="isCommandParams(item)">
                <div
                  class="command-command"
                  v-for="(val, idx) in item.template.params_values"
                  :key="idx"
                >
                  <span class="params-count">{{ val.key }}: </span>
                  <span class="params-name">{{ val.value }}</span>
                </div>
              </div>
              <div v-else>
                <div v-if="item.template && item.template.name" class="command-command">
                  {{ item.template.name }}
                </div>
                <div v-else class="command-answer">
                  {{ item.command }}
                </div>
              </div>
              <div class="command-answer">
                <span>{{ $t('monitoring.command_response') }}</span>
                {{ item.answer }}
              </div>
            </div>
            <div
              :class="
                item.status === 'SENT'
                  ? 'sent'
                  : item.status === 'WAITING'
                  ? 'waiting'
                  : 'timeout'
              "
              class="td__info"
            >
              {{ item.status }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="isFooterOpen" class="footer">
      <div class="footer__container">
        <el-select
          v-model="form.units"
          :placeholder="$t('monitoring.commands.object')"
          class="object__select"
          style="width: 99%"
          multiple
          collapse-tags
        >
          <el-option
            v-for="item in filterUnitsByType"
            :key="item.id"
            :value="item.id"
            :label="item.name"
          >
          </el-option>
        </el-select>
        <el-select
          v-model="form.commandsTemplate"
          :placeholder="$t('monitoring.commands.columns.command')"
          class="mt-2 object__select"
          style="width: 99%"
        >
          <el-option
            v-for="item in filterUnitsCommandTemplate"
            :key="item.id"
            :value="item.id"
            :label="item.name"
          >
          </el-option>
        </el-select>
        <div v-if="isExtraCommand && extraCommand && extraCommand.length">
          <el-input
            v-for="(item, index) in paramsInfo"
            :key="index"
            v-model="item.value"
            :placeholder="item.placeholder"
            class="mt-2 object__select"
          />
        </div>
        <div class="footer__btns">
          <button class="cancel-btn" @click="isFooterOpen = false">
            {{ $t('cancel') }}
          </button>
          <button @click="sendCommand" class="primary">{{ $t('send') }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import CloseIcon from '@/components/commands/assets/icons/CloseIcon.vue'
import SearchIcon from '@/components/commands/assets/icons/SearchIcon.vue'

export default {
  name: 'Commands',
  components: {
    CloseIcon,
    SearchIcon
  },

  props: {
    unit: {
      type: Object,
      default: () => {}
    }
  },

  data() {
    return {
      isExtraCommand: false,
      form: {
        terminalType: '',
        commandsTemplate: '',
        value: [],
        units: [],
        extraCommand: ''
      },
      thead: [
        {
          columnName: this.$t('monitoring.commands.columns.data')
        },
        {
          columnName: this.$t('monitoring.commands.columns.command')
        },
        {
          columnName: this.$t('monitoring.commands.columns.status')
        }
      ],
      searchCommands: '',
      isFooterOpen: false,
      params: [],
      paramsInfo: []
    }
  },

  computed: {
    ...mapGetters({
      commandsHistory: 'commands/commandsHistory',
      commandsTemplate: 'commands/commandsTemplate',
      units: 'units/units'
    }),
    ...mapState('dictionary', {
      terminalTypes: state => state.terminal_type
    }),

    ...mapState('login', {
      me: state => state.me
    }),

    extraCommand() {
      const command = this.filterUnitsCommandTemplate.find(
        val => val.id === this.form.commandsTemplate
      )
      const params = []
      if (!(command && command.params)) {
        return []
      }

      command.params.forEach(el => {
        let obj = {
          placeholder: el
        }
        params.push(obj)
      })
      this.paramsInfo = params

      return params
    },

    filterUnitsCommandTemplate() {
      return this.commandsTemplate.filter(
        el => el.terminal_type === this.unit.terminal_type.key
      )
    },

    filterUnitsByType() {
      return this.units.filter(el => el.terminal_type.key === this.unit.terminal_type.key)
    },
    filterCommands() {
      if (!this.searchCommands) return this.commandsHistory
      return this.commandsHistory.filter(el =>
        JSON.stringify(el.template.name)
          .toLowerCase()
          .includes(this.searchCommands.toLowerCase())
      )
    }
  },
  watch: {
    'form.commandsTemplate': function (newVal) {
      if (newVal) {
        this.params = []
        this.form.value = []
        this.form.extraCommand = ''
        this.commandsTemplate.forEach(el => {
          // if (el.params.length > 0 && el.id === newVal) {
          if (el.id === newVal) {
            this.params.push(el.params)
            if (!el.params.length) {
              this.isExtraCommand = false
            } else {
              // this.params.push(el.params[0])
              this.isExtraCommand = true
            }
          }
        })
        console.log('**', this.params)
      }
    }
  },

  methods: {
    ...mapActions({
      getCommandsHistory: 'commands/getCommandsHistory',
      getCommandsTemplate: 'commands/getCommandTemplate',
      send: 'commands/sendCommand'
    }),
    ...mapMutations({
      clearCommands: 'commands/setClearCommands'
    }),
    isCommandParams(item) {
      return (
        item &&
        item.length > 0 &&
        item.template &&
        item.template.params_values &&
        item.template.params_values.length
      )
    },
    async sendCommand() {
      if (!this.form.commandsTemplate || this.form.units.length === 0) {
        return this.$notify.error({
          duration: 5000,
          title: `${this.$t('error')} \u{1f62a}`,
          message: this.$t('monitoring.commands.error_message')
        })
      }
      const keys = [...this.params.flat()]
      const imeisArr = []
      const idsArr = []
      this.form.units.filter((el, index) => {
        this.units.forEach(item => {
          if (el === item.id) {
            imeisArr.push(item.imei)
            idsArr.push(item.name)
          }
        })
      })

      const params_values = this.paramsInfo.map((val, index) => {
        return {
          key: keys[index],
          value: val.value
        }
      })
      const params = {
        company_id: this.me.active_company_id,
        imeis: imeisArr,
        template: {
          id: this.form.commandsTemplate,
          params_values: params_values || []
          // params_values: filterParamsArray ? filterParamsArray : [],
        }
      }
      this.send(params)
      this.isFooterOpen = false
      this.form.terminalType = this.form.commandsTemplate = ''
      this.form.value = []
    },

    async openFooter() {
      await this.getCommandsTemplate()
      this.isFooterOpen = true

      this.form.units = []

      this.units.forEach(el => {
        if (el.id === this.unit.id) {
          this.form.units.push(el.id)
        }
      })

      this.form.commandsTemplate = ''
    },
    closeCommands() {
      this.clearCommands()
      this.$emit('close-commands')
    }
  },
  mounted() {
    this.getCommandsHistory({
      conditions: [{ field: 'imei', value: this.unit.imei }]
    })
    setTimeout(() => {
      console.log('ok', this.filterCommands)
    }, 1000)
  }
}
</script>

<style lang="stylus" scoped>
  .stripped
    background #F6F7FA
  .footer
    overflow-y: auto;
    display: flex
    padding 20px
    border-top 1px solid #CDD4DD
    position sticky
    bottom 0
    height 185px
    background white

    &__container
      width: 100%

      position sticky
      bottom 20px
    &__btns
      position sticky
      bottom: -20px
      width: 100%
      display flex
      margin-top:10px
      align-items: center
      justify-content center
      padding: 10px 0;
      background: white;
      .cancel-btn
        width: 50%
        margin-right 20px
        color : #C7472B

  .sent
    color #2FA37D !important
  .waiting
    color #DEAF3A !important
  .timeout
    color #C95716 !important
  .command__send
    cursor pointer
    margin 12px 0 0 0
    display  flex
    align-items  center
    justify-content  center
    color #446CA7
    &:hover
      color #3c5e8f
      transition all 0.4s ease
    svg
      margin-right  8px
    p
      font-family  'Source Sans Pro'
      font-style  normal
      font-weight  600
      font-size  16px
      line-height  120%
  .command__search
    position relative
    margin 0 20px
    .command__input
      border  1px solid #CDD4DD
      border-radius  4px
      height  36px
      width  100%
      background  #F6F7FA
      outline  none
      font-size  14px
      padding  10px
    .command__input::placeholder
      color #828D9B
      font-weight: 600
    .command__search__icon
      color red
      position absolute
      top 30%
      right 10px

.primary
  padding 11.5px 44.5px
  background-color #5477A9
  font-size 14px
  line-height 16px
  font-weight 600
  color #fff
  border-radius 12px
  border none
  outline none
  transition all 0.3s
  width: 50%
  &:hover
    background-color #3C5E8F
.wrapper
  position absolute
  top 20px
  bottom 50px
  left 640px
  width 520px
  z-index 30
  background-color #fff
  overflow-y hidden
  box-shadow 0 2px 12px 0 rgba(0,0,0,.1)

  .command-command {
    overflow: hidden;
    text-overflow: ellipsis;

    .params-count {
      color: #828d9b;
      font-size: 13px;
    }
    .params-name {
      color: #828d9b;
      font-size: 13px;
    }
  }

  &__header
    display flex
    align-items center
    justify-content space-between
    padding 17px 20px
    font-family Source Sans Pro

    .header
      &__title
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 120%;
        font-family Source Sans Pro
        color #000000
      &__close
        cursor pointer

  &__search
    display flex
    justify-content space-between
    padding 0 25px
    .search
      position relative
      width 268px
    .search-icon
      position absolute
      top 50%
      right 0
      transform translate(-50%, -50%)
  &__body
    font-family Source Sans Pro

    .thead

      display flex
      padding 22px 25px
      .tr
        font-weight 600
        line-height 17px
        font-size 16px
        color #000000
        text-align center
        &:nth-of-type(1)
          width 130px

        &:nth-of-type(2)
          width 239px

        &:nth-of-type(3)
          width 80px

    .tbody
      // height calc(100vh - 214px)
      height 70vh
      overflow-y auto
      padding-bottom: 10px
      .td
        text-align: center
        display flex
        align-items center
        justify-content: center
        padding-bottom: 5px
        &__info
          font-size 14px
          font-weight bold
          color #5477A9
          .command-answer
            span
              display block
              margin-top 6px
              margin-bottom 6px
              font-size 12px
              color #677785
              line-height 15px
          &:nth-of-type(1)
            width 120px
          &:nth-of-type(2)
            width 214px
            margin-right 25px
          &:nth-of-type(3)
            width 64px

::v-deep .el-select__tags-text
  max-width 100px
  width auto
</style>
